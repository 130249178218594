<template>
  <div v-loading="loading">
    <div class="search-normal-container clearfix">
      <div class="search-input-wrapper">
        <el-input
          prefix-icon="el-icon-search"
          v-model="searchText"
          size="mini"
          @keyup.enter.native="getList(1)"
          class="search-input"
        >
        </el-input>

        <el-tooltip effect="dark" :content="$t('handle.refresh')" placement="bottom-start">
          <el-button plain icon="el-icon-refresh" size="mini" @click="getList(currentPage)"></el-button>
        </el-tooltip>
      </div>
    </div>
    <el-table :data="podList">
      <el-table-column :label="$t('name')" prop="metadata.name" sortable>
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="Pod"></svg-icon>
            <div class="content">
              <a @click="toPod(scope.row.metadata)">
                {{ scope.row.metadata.name }}
              </a>

              <span class="desc">
                {{
                  scope.row.metadata.creationTimestamp
                    ? moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss")
                    : "-"
                }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('status')">
        <template slot-scope="scope">
          <span class="status" :class="mapPodStatus(scope.row.podStatus)">
            {{ scope.row.podStatus || "-" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('container')">
        <template slot-scope="scope">
          <container
            :containers="scope.row.spec.containers"
            :status="scope.row.status.containerStatuses"
            :pod="scope.row.metadata.name"
            :namespace="scope.row.metadata.namespace"
            :vendor="vendor"
            :region="region"
            :cluster="cluster"
            layout="table-column"
          />
        </template>
      </el-table-column>

      <!-- <el-table-column label="各容器用量" width="150">
        <template slot-scope="scope">
          <div v-for="item in scope.row.spec.containers" :key="item.name">
            <div class="title">{{ item.name }}</div>

            <div class="desc">CPU资源用量</div>
            <resource-percent type="cpu" :resources="item.resources" />
            <div class="desc">内存资源用量</div>
            <resource-percent type="memory" :resources="item.resources" />
          </div>
        </template>
      </el-table-column> -->

      <el-table-column :label="$t('containerStatus')" width="80">
        <template slot-scope="scope">
          <div class="status" :class="mapPodStatus(scope.row.podStatus)">
            <span>
              {{ mapReadyCount(scope.row) }}/{{ scope.row.spec.containers ? scope.row.spec.containers.length : 0 }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="QoS" width="80">
        <template slot-scope="scope">
          {{ scope.row.status.qosClass || "-" }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('podIP')">
        <template slot-scope="scope">
          <i class="el-icon-document-copy" v-clipboard:copy="scope.row.status.podIP"></i>
          {{ scope.row.status.podIP || "-" }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('nodeName')" prop="spec.nodeName" v-if="type != 'node'">
        <template slot-scope="scope">
          <i class="el-icon-document-copy" v-clipboard:copy="scope.row.spec.nodeName"></i>
          <a @click.prevent="toNode(scope.row.spec.nodeName)"> {{ scope.row.spec.nodeName }}</a>
        </template>
      </el-table-column>

      <el-table-column :label="$t('handle.handle')" width="100">
        <template slot-scope="scope">
          <a href="javascript:;" @click="restart(scope.row)">
            {{ $t("handle.restart") }}
          </a>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next, sizes, total"
      :total="podTotal"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { podList, podRestart } from "api/deployment";
import { nodePods } from "api/cluster";
import { pvPods } from "api/pv";
import Container from "./Container";
import moment from "moment";
import { mapPodStatus } from "utils";
import ResourcePercent from "@/components/ResourcePercent";

export default {
  components: {
    Container,
    ResourcePercent
  },

  props: {
    type: {
      type: String
    },

    name: {
      type: String
    },
    vendor: {
      type: String
    },
    region: {
      type: String
    },
    cluster: {
      type: String
    },
    namespace: {
      type: String
    }
  },

  data() {
    return {
      podList: [],
      podTotal: 0,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      chartTitle: {},
      searchText: ""
    };
  },

  methods: {
    moment,

    mapPodStatus,

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    getList(page = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = page;

      let action = null;
      let data = {};
      let params = { type: this.$route.params.type, page, pageSize, search: [this.searchText] };

      switch (this.type) {
        case "application":
          action = podList;
          data = {
            application: this.name,
            ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
          };

          break;

        case "node":
          action = nodePods;
          data = {
            node: this.name,
            ...{ vendor: this.vendor, region: this.region, cluster: this.cluster }
          };

          break;

        case "pvc":
          action = pvPods;
          data = {
            persistentvolumeclaim: this.name,
            ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
          };

          break;
      }

      action(data, params).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.podList = response.data.items;
          this.podTotal = response.data.total;
        }
      });
    },

    mapReadyCount(item) {
      let count = 0;
      if (item.status.containerStatuses) {
        item.status.containerStatuses.forEach(container => {
          if (container.ready) count++;
        });
      }

      return count;
    },

    toPod(metadata) {
      let { name, namespace } = metadata;

      this.$router.push({
        path: `/detail/Pod/${name}`,
        query: {
          vendor: this.vendor,
          region: this.region,
          cluster: this.cluster,
          namespace: this.namespace ? this.namespace : namespace
        }
      });
    },

    toNode(name) {
      this.$router.push({
        path: `/detail/Node/${name}`,
        query: { vendor: this.vendor, region: this.region, cluster: this.cluster }
      });
    },

    restart(row) {
      console.log(row);
      let { name, namespace } = row.metadata;

      this.$confirm(this.$t("restart", [name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          podRestart({ pod: name, ...this.$route.query, namespace }).then(response => {
            if (response.code === 0) {
              this.$notify({
                title: "Success",
                message: "Success",
                type: "success"
              });

              this.getList(this.currentPage);
            }
          });
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getList();
  }
};
</script>
