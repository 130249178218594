<template>
  <div>
    <div @click="scale">
      <slot>{{ $t("autoScale") }}</slot>
    </div>

    <el-dialog
      :title="$t('autoScale')"
      :visible.sync="scaleVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="autoScaleForm" ref="autoScaleForm" label-position="top" size="small" v-loading="loading">
        <el-form-item :label="$t('autoScaleSwitch')">
          <el-radio-group v-model="autoScaleForm.autoScaleSwitch" size="small">
            <el-radio-button :label="true">ON</el-radio-button>
            <el-radio-button :label="false">OFF</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          :label="$t('autoScaleResource')"
          prop="resource"
          v-if="autoScaleForm.autoScaleSwitch"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'change' }"
        >
          <el-select v-model="autoScaleForm.resource" multiple>
            <el-option :label="$t('cpu')" value="cpu"></el-option>
            <el-option :label="$t('memory')" value="memory"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('cpuThreshold')"
          v-if="autoScaleForm.autoScaleSwitch && autoScaleForm.resource.includes('cpu')"
          prop="cpuThreshold"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model="autoScaleForm.cpuThreshold">
            <el-select v-model="autoScaleForm.cpuUnit" slot="append" class="input-with-select">
              <el-option label="%" value="percent"></el-option>
              <el-option label="m" value="m"></el-option>
              <el-option label="core" value="core"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item
          :label="$t('memoryThreshold')"
          v-if="autoScaleForm.autoScaleSwitch && autoScaleForm.resource.includes('memory')"
          prop="memoryThreshold"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model="autoScaleForm.memoryThreshold">
            <el-select v-model="autoScaleForm.memoryUnit" slot="append" class="input-with-select">
              <el-option label="%" value="percent"></el-option>
              <el-option label="GiB" value="GiB"></el-option>
              <el-option label="MiB" value="MiB"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="最小副本数" v-if="autoScaleForm.autoScaleSwitch">
          <el-input v-model.number="autoScaleForm.minReplicas" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="prepend">
              {{ $t("replicas") }}
            </template>
          </el-input>
        </el-form-item>

        <el-form-item label="最大副本数" v-if="autoScaleForm.autoScaleSwitch">
          <el-input v-model.number="autoScaleForm.maxReplicas" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="prepend">
              {{ $t("replicas") }}
            </template>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="scaleLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isNull, endsWith } from "lodash";
import { deploymentDetail, applicationHpa, deleteHpa } from "api/deployment";
export default {
  props: {
    application: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      scaleVisible: false,

      loading: false,
      scaleLoading: false,

      autoScaleData: {
        metadata: {
          labels: {
            app: ""
          },
          name: "",
          namespace: ""
        },
        spec: {
          minReplicas: 1,
          maxReplicas: 10,
          scaleTargetRef: {
            name: "",
            kind: "",
            apiVersion: ""
          },
          metrics: []
        }
      },

      autoScaleForm: {
        autoScaleSwitch: false,
        resource: [],
        minReplicas: 1,
        maxReplicas: 10,
        cpuUnit: "percent",
        cpuThreshold: "60",
        memoryThreshold: "60",
        memoryUnit: "percent"
      }
    };
  },

  methods: {
    async scale() {
      this.scaleVisible = true;
      this.loading = true;

      let response = await deploymentDetail(
        {
          application: this.application,
          ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
        },
        { type: this.type }
      );

      if (response.code === 0) {
        this.loading = false;
        this.form = response.data;

        this.autoScaleForm.resource = [];
        this.autoScaleForm.autoScaleSwitch = !isNull(this.form.spec.hpa);

        if (this.autoScaleForm.autoScaleSwitch) {
          this.autoScaleForm.minReplicas = this.form.spec.hpa.spec.minReplicas;
          this.autoScaleForm.maxReplicas = this.form.spec.hpa.spec.maxReplicas;

          this.form.spec.hpa.spec.metrics.forEach(item => {
            if (item.resource.name == "memory") {
              this.autoScaleForm.resource.push("memory");
              if (item.resource.target.type == "Utilization") {
                this.autoScaleForm.memoryUnit = "percent";
                this.autoScaleForm.memoryThreshold = item.resource.target.averageUtilization;
              }

              if (item.resource.target.type == "Value") {
                if (endsWith(item.resource.target.value, "M")) {
                  this.autoScaleForm.memoryUnit = "MiB";
                  this.autoScaleForm.memoryThreshold = item.resource.target.value.replace("MiB", "");
                }

                if (endsWith(item.resource.target.value, "G")) {
                  this.autoScaleForm.memoryUnit = "GiB";
                  this.autoScaleForm.memoryThreshold = item.resource.target.value.replace("GiB", "");
                }
              }
            }

            if (item.resource.name == "cpu") {
              this.autoScaleForm.resource.push("cpu");
              if (item.resource.target.type == "Utilization") {
                this.autoScaleForm.cpuUnit = "percent";
                this.autoScaleForm.cpuThreshold = item.resource.target.averageUtilization;
              }

              if (item.resource.target.type == "Value") {
                if (endsWith(item.resource.target.value, "m")) {
                  this.autoScaleForm.cpuUnit = "m";
                  this.autoScaleForm.cpuThreshold = item.resource.target.value.replace("m", "");
                }

                if (endsWith(item.resource.target.value, "core")) {
                  this.autoScaleForm.cpuUnit = "core";
                  this.autoScaleForm.cpuThreshold = item.resource.target.value.replace("core", "");
                }
              }
            }
          });
        }
      }
    },

    async submit() {
      this.scaleLoading = true;

      if (this.autoScaleForm.autoScaleSwitch) {
        await this.hpaSubmit();
      }
      if (!this.autoScaleForm.autoScaleSwitch && !isNull(this.form.spec.hpa)) {
        await this.hpaDelete();
      }

      this.scaleLoading = false;
      this.scaleVisible = false;

      this.$emit("update");
    },

    async hpaSubmit() {
      this.$refs["autoScaleForm"].validate(valid => {
        if (valid) {
          if (this.autoScaleForm.autoScaleSwitch) {
            this.autoScaleData.spec.metrics = [];
            this.autoScaleForm.resource.forEach(item => {
              let target = {};
              if (item === "cpu") {
                if (this.autoScaleForm.cpuUnit == "percent") {
                  target = {
                    type: "Utilization",
                    averageUtilization: Number(this.autoScaleForm.cpuThreshold)
                  };
                }

                if (this.autoScaleForm.cpuUnit == "m") {
                  target = {
                    type: "Value",
                    value: `${this.autoScaleForm.cpuThreshold}m`
                  };
                }

                if (this.autoScaleForm.cpuUnit == "core") {
                  target = {
                    type: "Value",
                    value: this.autoScaleForm.cpuThreshold
                  };
                }
              }

              if (item === "memory") {
                if (this.autoScaleForm.memoryUnit == "percent") {
                  target = {
                    type: "Utilization",
                    averageUtilization: Number(this.autoScaleForm.memoryThreshold)
                  };
                }

                if (this.autoScaleForm.memoryUnit == "MiB") {
                  target = {
                    type: "Value",
                    value: `${this.autoScaleForm.memoryThreshold}M`
                  };
                }

                if (this.autoScaleForm.memoryUnit == "GiB") {
                  target = {
                    type: "Value",
                    value: `${this.autoScaleForm.memoryThreshold}G`
                  };
                }
              }

              this.autoScaleData.spec.metrics.push({
                type: "Resource",
                resource: {
                  name: item,
                  target
                }
              });

              this.autoScaleData.spec.minReplicas = this.autoScaleForm.minReplicas;
              this.autoScaleData.spec.maxReplicas = this.autoScaleForm.maxReplicas;

              this.autoScaleData.spec.scaleTargetRef = {
                name: this.form.metadata.name,
                kind: this.form.spec[this.type.toLowerCase()].kind,
                apiVersion: this.form.spec[this.type.toLowerCase()].apiVersion
              };

              this.autoScaleData.metadata.name = this.form.metadata.name;
              this.autoScaleData.metadata.namespace = this.form.metadata.namespace;
              this.autoScaleData.metadata.labels.app = this.form.metadata.name;
              this.autoScaleData.metadata.labels.type = "Deployment";
            });

            applicationHpa(
              { ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace } },
              this.autoScaleData
            ).then(response => {
              return response;
            });
          }
        }
      });
    },

    async hpaDelete() {
      deleteHpa(
        { ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace } },
        this.form.spec.hpa.metadata.name
      );
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
.input-with-select {
  width: 80px;
}
</style>
