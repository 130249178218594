<template>
  <div>
    <div @click="update">
      <slot>{{ $t("handle.update") }}</slot>
    </div>

    <el-dialog
      :title="$t('handle.update')"
      :visible.sync="updateVisible"
      top="30px"
      :close-on-click-modal="false"
      width="800px"
      :append-to-body="true"
    >
      <div v-loading="loading">
        <el-form :model="form" ref="updateForm" label-position="top" size="small" v-if="!loading">
          <el-form-item :label="$t('imageName')">
            <image-select :data.sync="image" :locationList="locationList" />
          </el-form-item>

          <el-form-item :label="$t('env')">
            <env :data="env" :namespace="namespace" :locationList="locationList" />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="updateLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deploymentDetail, applicationUpdate } from "api/deployment";
export default {
  props: {
    application: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      updateVisible: false,
      form: {},
      name: "",
      image: "",
      env: [],
      updateLoading: false,
      loading: true
    };
  },

  methods: {
    update() {
      this.updateVisible = true;
      this.loading = true;
      deploymentDetail(
        {
          application: this.application,
          ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
        },
        { type: this.type }
      ).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.form = response.data;
          this.name = response.data.metadata.name;
          this.image = response.data.spec[this.type.toLowerCase()].spec.template.spec.containers[0].image;
          this.env = response.data.spec[this.type.toLowerCase()].spec.template.spec.containers[0].env || [];
        }
      });
    },

    submit() {
      this.form.spec[this.type.toLowerCase()].spec.template.spec.containers[0].image = this.image;
      this.form.spec[this.type.toLowerCase()].spec.template.spec.containers[0].env = this.env;

      this.updateLoading = true;

      applicationUpdate(this.form, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
        response => {
          this.updateLoading = false;
          if (response.code === 0) {
            this.updateVisible = false;
            this.$emit("update");
            this.$notify({
              title: "Success",
              message: "Success",
              type: "success"
            });
          }
        }
      );
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    locationList() {
      return [this.vendor, this.region, this.cluster];
    }
  }
};
</script>
