<template>
  <div>
    <div @click="scale">
      <slot> {{ $t("scaleByHand") }}</slot>
    </div>

    <el-dialog
      :title="$t('scaleByHand')"
      :visible.sync="scaleVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="form" ref="scaleForm" size="small" v-loading="loading" label-position="top">
        <el-form-item :label="$t('application')" prop="name">
          <el-input v-model="name" disabled></el-input>
        </el-form-item>

        <el-form-item :label="$t('replicas')" prop="slider">
          <el-input v-model.number="slider" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="prepend">{{ $t("replicas") }}</template>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="handleSubmit" size="small" :loading="scaleLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { applicationUpdate, deploymentDetail } from "api/deployment";
export default {
  props: {
    application: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      scaleVisible: false,
      name: "",
      slider: 1,
      form: {},
      loading: false,
      scaleLoading: false
    };
  },

  methods: {
    async scale() {
      this.scaleVisible = true;
      this.loading = true;

      let response = await deploymentDetail(
        {
          application: this.application,
          ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
        },
        { type: this.type }
      );

      if (response.code === 0) {
        this.loading = false;
        this.form = response.data;
        this.name = this.form.metadata.name;
        this.slider = this.form.spec[this.type.toLowerCase()].spec.replicas;
      }
    },

    handleSubmit() {
      this.form.spec[this.type.toLowerCase()].spec.replicas = this.slider;
      this.scaleLoading = true;

      applicationUpdate(this.form, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
        response => {
          this.scaleLoading = false;
          if (response.code === 0) {
            this.scaleVisible = false;
            this.$emit("update");
          }
        }
      );
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
.input-with-select {
  width: 80px;
}
</style>
